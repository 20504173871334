<template>
  <validation-observer ref="observer">
    <form @submit.prevent="save">
      <div>
        <div class="pa-9 mx-10">
          <v-container>
            <v-row align="center">
              <v-col cols="12" class="mb-6">
                <h3 class="page-title font-weight-bold">クレーム編集</h3>
                <span>クレーム情報を編集します。</span>
              </v-col>
              <v-col cols="12">
                <div class="form-content">
                  <v-row align="center">
                    <v-col
                      cols="3"
                      class="form-header text-heading-3 text-right"
                      >顧客名</v-col
                    >
                    <v-col cols="4" class="flex-grow-1">
                      <validation-provider
                        v-slot="{ errors }"
                        name="claimCustomer"
                        rules="required"
                      >
                        <v-autocomplete
                          class="customer-box-form-input"
                          :error-messages="errors"
                          :error="errors.length !== 0"
                          hide-details="auto"
                          placeholder="顧客名を入力してください"
                          outlined
                          :items="customers"
                          item-text="name"
                          item-value="id"
                          dense
                          autocomplete="off"
                          v-model="data.customer"
                          :search-input.sync="search"
                          hide-no-data
                          :loading="smallLoading.customer"
                          @change="updateSiteList()"
                          @update:search-input="searchCustomer"
                          return-object
                        >
                        </v-autocomplete>
                      </validation-provider>
                    </v-col>
                  </v-row>

                  <v-row align="center">
                    <v-col
                      cols="3"
                      class="form-header text-heading-3 text-right"
                      >物件名</v-col
                    >
                    <v-col cols="4" class="flex-grow-1">
                      <validation-provider
                        v-slot="{ errors }"
                        name="claimSite"
                        rules="required"
                      >
                        <v-select
                          class="customer-box-form-input"
                          hide-details="auto"
                          placeholder="物件名を入力してください"
                          outlined
                          dense
                          :items="sites"
                          :error-messages="errors"
                          :error="errors.length !== 0"
                          v-model="data.site"
                          item-text="name"
                          item-value="id"
                          return-object
                          :disabled="smallLoading.customer"
                          :loading="smallLoading.site"
                        ></v-select>
                      </validation-provider>
                    </v-col>
                  </v-row>
                  <v-row align="center">
                    <v-col
                      cols="3"
                      class="form-header text-heading-3 text-right"
                      >サービスタイプ</v-col
                    >
                    <v-col cols="4" class="flex-grow-1">
                      <validation-provider
                        v-slot="{ errors }"
                        name="claimService"
                        rules="required"
                      >
                        <v-select
                          class="customer-box-form-input"
                          dense
                          outlined
                          placeholder="サービスタイプを選択してください"
                          hide-details="auto"
                          v-model="data.service_id"
                          :error-messages="errors"
                          :error="errors.length !== 0"
                          :items="services"
                          item-value="id"
                          item-text="name"
                          :disabled="smallLoading.site"
                        ></v-select>
                      </validation-provider>
                    </v-col>
                  </v-row>

                  <v-row align="center">
                    <v-col
                      cols="3"
                      class="form-header text-heading-3 text-right"
                      >タスク</v-col
                    >
                    <v-col cols="4" class="flex-grow-1">
                      <validation-provider
                        v-slot="{ errors }"
                        name="claimTask"
                        rules="required"
                      >
                        <v-select
                          class="customer-box-form-input"
                          dense
                          outlined
                          placeholder="task id / 日付 / サービス名 "
                          hide-details="auto"
                          :error-messages="errors"
                          v-model="data.task_id"
                          :items="tasks"
                          item-value="id"
                          item-text="text"
                          :disabled="data.service_id === null"
                        >
                        </v-select>
                      </validation-provider>
                    </v-col>
                  </v-row>

                  <v-row align="center">
                    <v-col
                      cols="3"
                      class="form-header text-heading-3 text-right"
                      >クレーム分類</v-col
                    >
                    <v-col cols="4" class="flex-grow-1">
                      <validation-provider
                        v-slot="{ errors }"
                        name="claimCategory"
                        rules="required"
                      >
                        <v-select
                          class="customer-box-form-input"
                          outlined
                          dense
                          :items="claimCategories"
                          :error-messages="errors"
                          :error="errors.length !== 0"
                          hide-details="auto"
                          placeholder="クレーム分類を選択してください"
                          chips
                          multiple
                          clearable
                          v-model="data.claim_category_id"
                        >
                          <template v-slot:selection="{ attrs, item, select }">
                            <v-chip
                              class="mb-1"
                              label
                              small
                              close
                              color="#8C5F5F"
                              text-color="white"
                              v-bind="attrs"
                              @click="select"
                              @click:close="removeTag(item.value)"
                            >
                              <span>{{ item.text }}</span>
                            </v-chip>
                          </template>
                        </v-select>
                      </validation-provider>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                      cols="3"
                      class="form-header text-heading-3 text-right"
                      >クレーム内容</v-col
                    >
                    <v-col cols="9" class="flex-grow-1">
                      <validation-provider
                        v-slot="{ errors }"
                        name="claimDescription"
                        :rules="`required|max:${descriptionMaxLength}`"
                      >
                        <v-textarea
                          class="customer-box-form-input"
                          dense
                          outlined
                          placeholder="クレームの内容を箇条書きにしてください"
                          hide-details="auto"
                          :error-messages="errors"
                          v-model="data.description"
                          :maxlength="descriptionMaxLength"
                        >
                        </v-textarea>
                      </validation-provider>
                      <div class="text-right mt-1">
                        {{
                          `${data.description.length}/${descriptionMaxLength}`
                        }}文字
                      </div>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col
                      cols="3"
                      class="form-header text-heading-3 text-right"
                      >対応内容</v-col
                    >
                    <v-col cols="9" class="flex-grow-1">
                      <validation-provider
                        v-slot="{ errors }"
                        name="claimResponse"
                        :rules="`required|max:${responseMaxLength}`"
                      >
                        <v-textarea
                          class="customer-box-form-input"
                          dense
                          outlined
                          placeholder="どのように対応したかを記入してください"
                          hide-details="auto"
                          :error-messages="errors"
                          :error="errors.length !== 0"
                          v-model="data.response"
                          :maxlength="responseMaxLength"
                        ></v-textarea>
                      </validation-provider>
                      <div class="text-right mt-1">
                        {{ `${data.response.length}/${responseMaxLength}` }}文字
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <div class="create-task-footer px-6">
          <v-row class="justify-end">
            <v-col cols="auto">
              <v-btn
                :to="{
                  name: 'ClaimList'
                }"
                text
                rounded
                min-width="150"
                class="mr-8"
                >キャンセル</v-btn
              >
            </v-col>

            <v-col cols="auto">
              <v-btn
                @click="save"
                color="#4F55A7"
                class="white--text"
                min-width="150"
                :loading="smallLoading.submit"
                >登録</v-btn
              >
            </v-col>
          </v-row>
        </div>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import { mapGetters } from 'vuex'
import { debounce } from 'lodash'
export default {
  name: 'Create',
  components: {},
  data() {
    return {
      loading: true,
      smallLoading: {
        customer: false,
        site: false,
        submit: false
      },
      initialLoad: true,
      search: '',
      data: {
        siteKey: null,
        service_id: null,
        description: '',
        response: '',
        task_id: '',
        claim_category_id: [],
        customer_id: 0,
        customer: null,
        site_id: 0,
        site: null,
        created_by: '',
        updated_by: '',
        claim_id: ''
      },
      descriptionMaxLength: 1000,
      responseMaxLength: 1000
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'searchedCustomers',
      'allSites',
      'allClaimCategories'
    ]),
    claimCategories() {
      return this.allClaimCategories.map(category => {
        return { value: category.id, text: category.name }
      })
    },
    customers() {
      if (this.smallLoading.customer || this.initialLoad) return []
      return this.searchedCustomers
    },
    sites() {
      return this.allSites
    },
    services() {
      if (!this.data.site?.projects) return []
      return this.data.site?.projects?.filter(service => service.service_type_id !== 0).map(service => {
        return {
          id: service.service_type_id,
          name: service.service_type
        }
      })
    },
    tasks() {
      return this.data.site?.tasks
        ?.filter(task => task.service_type_id === this.data.service_id)
        .map(task => {
          return {
            id: task.id,
            text: `${task.id} / ${task.date} / ${task.service.name}`
          }
        })
    }
  },
  async created() {
    await this.$store.dispatch('CLAIM_CATEGORY_GET_ALL')
    await this.$store.dispatch('SERVICE_GET_ALL')
  },
  methods: {
    searchCustomer: debounce(async function() {
      if (!this.search || this.search === this.data.customer?.name) return
      let params = {}
      params.search = this.search
      params.without_site = true
      this.smallLoading.customer = true
      this.$store.dispatch('CUSTOMER_GET_ALL', params).then(() => {
        this.smallLoading.customer = false
        this.initialLoad = false
      })
    }, 1000),
    updateSiteList() {
      if (!this.initialLoad) {
        this.data.site = null
        this.data.service_id = null
      }

      let params = {}
      params.id = this.data.customer.id
      this.smallLoading.site = true
      this.$store.dispatch('GET_SITE_BY_CUSTOMER', params).then(() => {
        this.smallLoading.site = false
      })
    },
    save() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return
        }
        this.smallLoading.submit = true
        let params = {}
        params.description = this.data.description
        params.response = this.data.response
        params.task_id = this.data.task_id
        params.claim_category_id = this.data.claim_category_id
        params.customer_id = this.data.customer?.id
        params.created_by = this.user.id
        params.updated_by = this.user.id

        this.$store
          .dispatch('CUSTOMER_CREATE_CLAIM', params)
          .then(
            response => {
              if (response.data.status == 'success') {
                this.$router.push({
                  name: 'ClaimList'
                })
              }
            },
            error => {
              throw error
            }
          )
          .finally(() => (this.smallLoading.submit = false))
      })
    },
    removeTag(item) {
      this.data.claim_category_id = this.data.claim_category_id.filter(
        claimCategories => claimCategories !== item
      )
    }
  }
}
</script>

<style src="./Create.scss" lang="scss" scoped></style>
